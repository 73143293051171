import { DatSection } from '@achieve/cx-debt-assessment'
import Section from 'components/Section/Section'
import styles from './DebtAssessmentSection.module.scss'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import { useRef, useEffect, useState } from 'react'
import { AchieveTheme as theme, Box, Skeleton } from '@achieve/ascend'

import { useTrackLoadEvent } from 'hooks/useTrackLoadEvent'
import { useMemoizedContentGetter } from 'utils/contentful'
import { TitleDivider } from 'components/TitleDivider'
import { useRouter } from 'next/router'

const DEFAULT_SELECTED_VALUE = 0

function DebtAssessmentSection({ content }) {
  const trackRefCta = useRef()
  const trackRefLink = useRef()
  const trackRefSelect = useRef()
  const { eyebrow, title } = useMemoizedContentGetter(content, ['eyebrow', 'title'])
  const { query } = useRouter()

  const appendQueryParams = (url, query) => {
    const existingParams = url.includes('?') ? url.split('?')[1] : ''
    const currentParams = new URLSearchParams(existingParams)
    const additionalParams = new URLSearchParams(query)

    for (const [key, value] of additionalParams) {
      currentParams.set(key, value)
    }

    const baseUrl = url.split('?')[0]
    const updatedQuery = currentParams.toString()
    return updatedQuery ? `${baseUrl}?${updatedQuery}` : baseUrl
  }

  let additionalConfiguration = content?.fields?.additionalConfiguration?.jsonContent
    ? {
        ...content.fields.additionalConfiguration.jsonContent,
        defaultSelectedValue: Number(
          content?.fields?.additionalConfiguration?.jsonContent?.defaultSelectedValue
        ),
      }
    : {}
  if (additionalConfiguration?.redirectWithQueryParams) {
    additionalConfiguration = {
      ...additionalConfiguration,
      CtaUrl: appendQueryParams(additionalConfiguration.CtaUrl, query),
      datWaitProps: {
        ...additionalConfiguration.datWaitProps,
        linkHref: appendQueryParams(additionalConfiguration.datWaitProps.linkHref, query),
      },
    }
  }

  const [formSelectedValue, setFormSelectedValue] = useState(DEFAULT_SELECTED_VALUE)
  const [loading, setLoading] = useState(true)

  const args = {
    ...additionalConfiguration,
    onCtaClick: function () {
      trackRefCta?.current?.click()
    },
    onClick: function () {
      trackRefLink?.current?.click()
    },
    fireEvent: function (value) {
      setFormSelectedValue(value)
    },
  }

  const backgroundColorMap = {
    white: theme.sb?.colors?.neutral?.white,
    lightGray: theme.sb?.colors?.neutral?.grey?.[8],
    lightBlue: theme.sb?.colors?.primary?.highlightBlue,
  }

  useTrackLoadEvent({
    event_action: 'app_event',
    form_ss_amount: `${
      additionalConfiguration?.defaultSelectedValue ??
      additionalConfiguration?.selectItemList?.[0]?.value
    }`,
    track_event: 'tool_render',
  })

  // useEffect to handle changes from the select field
  useEffect(() => {
    if (formSelectedValue) {
      // Trigger event to track callback event
      trackRefSelect?.current?.click()
    }
    // Defaults to loading, stop it once an initial value is received
    loading && setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSelectedValue])

  return (
    <Section
      backgroundColor={
        backgroundColorMap[additionalConfiguration?.backgroundColor] ?? backgroundColorMap.white
      }
      className={styles['container']}
    >
      <Box className={styles['box-form']}>
        <TitleDivider eyebrowBlack={true} eyebrow={eyebrow} title={title} />
        <TrackingWrapper
          data-testid="dat-wait-link-click"
          track={{
            list_name: 'DAT WAIT LINK',
            click_id: additionalConfiguration?.datWaitProps?.linkText,
            click_text: `Achieve-Web | ${additionalConfiguration?.datWaitProps?.linkText}`,
            click_url: additionalConfiguration?.datWaitProps?.linkHref,
          }}
        >
          <div ref={trackRefLink} />
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dat-button-click"
          track={{
            list_name: 'DAT BUTTON',
            click_id: additionalConfiguration?.CtaCopy,
            click_text: `Achieve-Web | ${additionalConfiguration?.CtaCopy}`,
            click_url: additionalConfiguration?.CtaUrl,
          }}
        >
          <div ref={trackRefCta} />
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dat-select-field"
          track={{
            list_name: 'DAT SELECT FIELD',
            click_id: formSelectedValue,
            click_text: `Achieve-Web | ${formSelectedValue}`,
            form_ss_amount: formSelectedValue,
          }}
        >
          <div ref={trackRefSelect} />
        </TrackingWrapper>
        {loading ? (
          <Skeleton variant="rectangular">
            <DatSection {...args} />
          </Skeleton>
        ) : (
          <DatSection {...args} />
        )}
      </Box>
    </Section>
  )
}
export { DebtAssessmentSection }
export default DebtAssessmentSection
